// SidebarLogo.js
import React from 'react';
import newTidLogo from '../assets/new_tid_logo.png'; // Adjust the path as needed
import './SidebarLogo.css';

const SidebarLogo = () => {
    return (
        <div className="sidebar-logo">
            <img src={newTidLogo} alt="Tap In Digital Full Logo" className="full-logo-image" />
        </div>
    );
};

export default SidebarLogo;